<template>
    <div v-loading="loading" class="p-5 bg-white rounded-lg min-h-[500px]">
        <div>
            <h2 class="my-5 text-2xl font-bold text-center">
                前台頁面顯示設定
            </h2>
            <div class="flex w-full">
                <div class="flex items-center px-5 mx-auto w-[500px]">
                    <h2 class="mr-5 w-[300px] text-base font-medium md:text-xl">
                        開關 <strong>Instagram</strong> 顯示
                    </h2>
                    <el-switch
                        v-model="form.enable_instagram"
                        class="flex-1"
                        active-color="#FF5733"
                        inactive-color="#e5e5e5"
                        :active-value="true"
                        :inactive-value="false"
                        @change="updateSettings"
                    >
                    </el-switch>
                </div>
                <div class="flex bg-gray-100 w-[1px]"></div>
                <div class="flex items-center px-5 mx-auto w-[500px]">
                    <h2 class="mr-5 w-[300px] text-base font-medium md:text-xl">
                        開關 <strong>評論時間</strong> 顯示
                    </h2>
                    <el-switch
                        v-model="form.enable_comment_time"
                        class="flex-1"
                        active-color="#FF5733"
                        inactive-color="#e5e5e5"
                        :active-value="true"
                        :inactive-value="false"
                        @change="updateSettings"
                    >
                    </el-switch>
                </div>
            </div>
            <div class="flex w-full my-10">
                <div class="flex items-center px-5 mx-auto w-[500px]">
                    <h2 class="mr-5 w-[300px] text-base font-medium md:text-xl">
                        開關 <strong>免收平台服務費(開啟＝免收)</strong>
                    </h2>
                    <el-switch
                        v-model="form.enable_sales_to_fee"
                        class="flex-1"
                        active-color="#FF5733"
                        inactive-color="#e5e5e5"
                        :active-value="true"
                        :inactive-value="false"
                        @change="updateSettings"
                    >
                    </el-switch>
                </div>
                <div class="flex bg-gray-100 w-[1px]"></div>
                <div class="flex items-center px-5 mx-auto w-[500px]"></div>
            </div>
        </div>
        <div class="border-t border-gray-100 w-full h-[1px] my-10"></div>
        <div>
            <div class="flex w-full">
                <div class="flex items-center px-5 mx-auto w-[500px]">
                    <div>
                        <div class="flex items-center mt-2 w-[500px]">
                            <h2
                                class="mr-5 w-[300px] text-base font-medium md:text-xl"
                            >
                                重要公告跑馬燈速度
                            </h2>
                            <el-input
                                v-model.number="form.news_marquee_speed"
                                style="width: 150px"
                            >
                            </el-input>
                        </div>
                        <!-- <div class="flex items-center mt-2 w-[500px]">
                            <h2
                                class="mr-5 w-[300px] text-base font-medium md:text-xl"
                            >
                                即刻快閃公告跑馬燈速度
                            </h2>
                            <el-input
                                v-model.number="
                                    form.rightNowActivity_marquee_speed
                                "
                                style="width: 150px"
                            >
                            </el-input>
                        </div> -->
                        <div class="flex items-center mt-2 w-[500px]">
                            <h2
                                class="mr-5 w-[300px] text-base font-medium md:text-xl"
                            >
                                IosAPP重要公告跑馬燈速度
                            </h2>
                            <el-input
                                v-model.number="form.news_marquee_speed_by_ios"
                                style="width: 150px"
                            >
                            </el-input>
                        </div>
                    </div>
                </div>
                <div class="flex bg-gray-100 w-[1px]"></div>
                <div class="flex items-center px-5 mx-auto w-[500px]">
                    <h2 class="mr-5 w-[300px] text-base font-medium md:text-xl">
                        圖片上傳大小(MB)
                    </h2>
                    <el-input
                        v-model.number="form.upload_photo_size"
                        style="width: 150px"
                    >
                    </el-input>
                </div>
            </div>
        </div>

        <div class="border-t border-gray-100 w-full h-[1px] my-10"></div>
        <div>
            <h2 class="my-5 text-2xl font-bold text-center">即刻快閃設定</h2>
            <div class="flex w-full">
                <div class="flex items-center px-5 mx-auto w-[500px]">
                    <h2 class="mr-5 w-[300px] text-base font-medium md:text-xl">
                        開單封鎖原因
                    </h2>
                    <el-input
                        v-model="form.demand.permission_denied_note"
                        type="textarea"
                        :rows="5"
                        style="width: 350px"
                    >
                    </el-input>
                </div>
                <div class="flex bg-gray-100 w-[1px]"></div>
                <div class="flex items-center px-5 mx-auto w-[500px]">
                    <h2
                        class="mr-5 w-[300px] text-base font-medium md:text-xl"
                    ></h2>
                </div>
            </div>
        </div>

        <div class="border-t border-gray-100 w-full h-[1px] my-10"></div>
        <div>
            <h2 class="my-5 text-2xl font-bold text-center">SEO 設定</h2>
            <div class="flex w-full">
                <div class="flex items-center px-5 mx-auto w-[500px]">
                    <h2 class="mr-5 w-[300px] text-base font-medium md:text-xl">
                        SEO 標題
                    </h2>
                    <el-input
                        v-model="form.seo_title"
                        type="textarea"
                        :rows="5"
                        style="width: 350px"
                    >
                    </el-input>
                </div>
                <div class="flex bg-gray-100 w-[1px]"></div>
                <div class="flex items-center px-5 mx-auto w-[500px]">
                    <h2 class="mr-5 w-[300px] text-base font-medium md:text-xl">
                        SEO 描述
                    </h2>
                    <el-input
                        v-model="form.seo_description"
                        type="textarea"
                        :rows="5"
                        style="width: 500px"
                    >
                    </el-input>
                </div>
            </div>
        </div>
        <div class="border-t border-gray-100 w-full h-[1px] my-10"></div>
        <div>
            <h2 class="my-5 text-2xl font-bold text-center">網站規則</h2>
            <div class="px-5 my-10 border-b">
                <div class="flex">
                    <h2 class="mr-5 text-base font-medium md:text-xl">
                        預訂注意事項
                    </h2>

                    <button
                        v-permission="['create']"
                        class="bg-green-500 w-[30px] h-[30px] text-white rounded-full"
                        @click.prevent="addImportant"
                    >
                        <font-awesome-icon :icon="['fas', 'plus']" />
                    </button>
                </div>
                <ul>
                    <li v-for="(item, key) in formImportantList" :key="key">
                        <div class="flex items-center mb-2">
                            <div class="mr-2">
                                <button
                                    class="bg-red-500 text-white w-[30px] h-[30px] rounded-full"
                                    @click.prevent="dropImportant(key)"
                                >
                                    <font-awesome-icon
                                        :icon="['fas', 'trash-alt']"
                                    />
                                </button>
                            </div>
                            <label class="mr-2 whitespace-nowrap w-[200px]"
                                >{{ key }}:</label
                            >
                            <div class="flex-1">
                                <el-input
                                    v-model="formImportantList[key]"
                                ></el-input>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="px-5 my-10 border-b">
                <div class="flex">
                    <h2 class="mr-5 text-base font-medium md:text-xl">
                        常見問題
                    </h2>

                    <button
                        v-permission="['create']"
                        class="bg-green-500 w-[30px] h-[30px] text-white rounded-full"
                        @click.prevent="addQuestions"
                    >
                        <font-awesome-icon :icon="['fas', 'plus']" />
                    </button>
                </div>
                <ul>
                    <li v-for="(item, key) in formQuestions" :key="key">
                        <div class="flex items-center mb-2">
                            <div class="mr-2">
                                <button
                                    class="bg-red-500 text-white w-[30px] h-[30px] rounded-full"
                                    @click.prevent="dropQuestions(key)"
                                >
                                    <font-awesome-icon
                                        :icon="['fas', 'trash-alt']"
                                    />
                                </button>
                            </div>
                            <label class="mr-2 whitespace-nowrap w-[200px]"
                                >{{ key }}:</label
                            >
                            <div class="flex-1">
                                <span>標題</span>
                                <el-input
                                    v-model="formQuestions[key].title"
                                ></el-input>
                                <span>內容</span>
                                <el-input
                                    v-model="formQuestions[key].content"
                                ></el-input>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="flex items-end justify-center my-10">
            <el-button
                v-permission="['create', 'update']"
                type="primary"
                native-type="submit"
                @click.prevent="updateSettings"
            >
                確定
            </el-button>
        </div>
    </div>
</template>

<script >
import { ref, reactive, del, set, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
import { firebaseConnectRef } from "@/plugins/firebase";
import firebase from "firebase/app";
// 取得全域 this 方法 ex: this.$api, this.$message
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    proxy
  } = getCurrentInstance();
  const loading = ref(false);
  const defaultSet = {
    enable_instagram: false,
    enable_comment_time: false,
    news_marquee_speed: 10000,
    seo_title: "CityBanana | 城市生活中最好玩的社交體驗",
    seo_description: "台灣網美互動首選品牌，找正妹參與活動就到CityBanana。休閒娛樂、陪讀、陪吃、陪玩、社交、活動、體驗，讓您在城市中發現令人驚喜的生活樂趣。",
    // 預訂重要事項
    important_list: {},
    // 常見問題
    questions: {},
    // 即刻快閃設定值
    demand: {
      // 即刻快閃開單封鎖原因
      permission_denied_note: ""
    }
  };
  const form = ref(defaultSet);
  // 預訂注意事項
  const formImportantList = ref({});
  // 常見問題
  const formQuestions = ref({});
  // 更新失敗時的預設值
  const setData = ref(defaultSet);

  // 新增重要事項
  async function addImportant() {
    try {
      const newKey = await firebase.database().ref("client_ui_settings/important_list").push().key;
      const obj = {};
      Object.keys(formImportantList.value).forEach(key => {
        obj[key] = formImportantList.value[key];
      });
      obj[newKey] = "";
      formImportantList.value = obj;
    } catch (err) {
      console.log(err);
      proxy.$message({
        type: "error",
        message: "新增重要訊息事項失敗"
      });
    }
  }
  /**
   * 刪除重要事項
   * @param { type String(字串) } key 刪除key
   */
  async function dropImportant(key) {
    try {
      await this.$confirm(`刪除此重要事項${key}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      });
      delete formImportantList.value[key];
      firebase.database().ref("client_ui_settings/important_list/" + key).remove();
      await getImportantList();
      proxy.$message({
        type: "success",
        message: "刪除成功"
      });
    } catch (err) {
      console.log(err);
    }
  }

  // 新增常見問題
  async function addQuestions() {
    try {
      const newKey = await firebase.database().ref("client_ui_settings/questions").push().key;
      const obj = {};
      Object.keys(formQuestions.value).forEach(key => {
        obj[key] = formQuestions.value[key];
      });
      obj[newKey] = {
        title: "",
        content: ""
      };
      formQuestions.value = obj;
    } catch (err) {
      console.log(err);
      proxy.$message({
        type: "error",
        message: "新增重要訊息事項失敗"
      });
    }
  }
  /**
   * 刪除常見問題
   * @param { type String(字串) } key 刪除key
   */
  async function dropQuestions(key) {
    try {
      await this.$confirm(`刪除此常見問題${key}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      });
      delete formQuestions.value[key];
      firebase.database().ref("client_ui_settings/questions/" + key).remove();
      await getQuestions();
      proxy.$message({
        type: "success",
        message: "刪除成功"
      });
    } catch (err) {
      console.log(err);
    }
  }

  // 取得設定值
  async function getSettings() {
    loading.value = true;
    try {
      const data = await firebaseConnectRef("client_ui_settings").get();
      if (!data.exists()) {
        form.value = defaultSet;
        return;
      }
      delete data.val().updatedAt;
      delete data.val().important_list;
      delete data.val().questions;
      const result = data.val();
      // 判斷沒有設定即刻快閃相關設定值 key 時 給予預設值
      if (!result.demand) {
        result.demand = {
          permission_denied_note: ""
        };
      }
      form.value = result;

      // 設定表單預設值（以防更新失敗時做還原)
      setData.value = data.val();
      return;
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "取得前台開關設定值失敗"
      });
    } finally {
      loading.value = false;
    }
  }

  // 取得預訂注意事項設定值
  async function getImportantList() {
    loading.value = true;
    try {
      const data = await firebaseConnectRef("client_ui_settings/important_list").get();
      if (data.exists()) {
        formImportantList.value = data.val();
        return;
      }
      return;
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "取得預訂注意事項失敗"
      });
    } finally {
      loading.value = false;
    }
  }

  // 取得常見問題
  async function getQuestions() {
    loading.value = true;
    try {
      const data = await firebaseConnectRef("client_ui_settings/questions").get();
      if (data.exists()) {
        formQuestions.value = data.val();
        return;
      }
      return;
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "取得常見問題失敗"
      });
    } finally {
      loading.value = false;
    }
  }

  // 更新設定值
  async function updateSettings() {
    loading.value = true;
    try {
      await firebaseConnectRef("client_ui_settings").set({
        ...form.value,
        important_list: formImportantList.value,
        questions: formQuestions.value,
        updatedAt: firebase.database.ServerValue.TIMESTAMP
      });
      proxy.$message({
        type: "success",
        message: "更新成功"
      });
    } catch (err) {
      console.log(err);
      // 更新失敗時將表單資料還原成預設值
      form.value = setData.value();
      proxy.$message({
        type: "error",
        message: "更新失敗"
      });
    } finally {
      loading.value = false;
    }
  }
  getSettings();
  getQuestions();
  getImportantList();
  return {
    loading,
    form,
    formImportantList,
    formQuestions,
    addImportant,
    dropImportant,
    addQuestions,
    dropQuestions,
    updateSettings
  };
};
export default __sfc_main;
</script>
